.App {
  text-align: left;
}

#App-header {
  background-color: #484a4d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px 10px 10px 10px;

}

.App-link {
  font-size: 15px;
  color: #0ac5f9;
}

.logo {
  height: 50px;
  border: 4px solid white;
}

/* Flex container for bio and projects */
#maincontainer {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.blurb {
  font-weight: bold;
  text-align: center;
}

.list {
  color: rgb(89, 89, 89);
}

#name {
  font-size: 50px;
}

#centerskills {
  text-align: center;
}

#centereducation {
  text-align: center;
}

#pixaliveicons {
  text-align: center;
}

#gracehoppericons {
  text-align: center;
}

#energyvizicons {
  text-align: center;
}




